import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { HeaderLayout } from '../../common/header';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import { ProfileIcons } from '../../common/icons';
import { useState } from 'react';
import { GenerateAccountConfirmationModal, LocaleModal } from '../../common/modals';
import { selectLocale } from '../../../services/root';
import { useAppSelector } from '../../../hooks';

const MyDocumentsLayout = () => {
	const locale = useAppSelector(selectLocale);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [localLocale, setLocalLocale] = useState<string>(locale);

	const [
		isGenerateAccountConfirmationModalOpen,
		setIsGenerateAccountConfirmationModalOpen,
	] = useState<boolean>(false);
	const [isLocaleModalOpen, setIsLocaleModalOpen] = useState<boolean>(false);

	const handleGenerateAccountConfirmationModalOpen = () => {
		setIsGenerateAccountConfirmationModalOpen(
			!isGenerateAccountConfirmationModalOpen
		);
	};

	const handleModalOpen = () => {
		setIsLocaleModalOpen(!isLocaleModalOpen);
	};

	const handleLocaleItemClick = (i: string) => {
		setIsLocaleModalOpen(false);
		setLocalLocale(i);
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout isAboutHeader isOnlyHeader={'myDocuments_title'} />
				<GenerateAccountConfirmationModal
					locale={localLocale}
					isModalOpen={isGenerateAccountConfirmationModalOpen}
					handleIsModalOpen={handleGenerateAccountConfirmationModalOpen}
					handleLocaleModalOpen={handleModalOpen}
				/>
				<LocaleModal
					isModalOpen={isLocaleModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleLocaleItemClick}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>

						<div className="commonContainer">
							<div
								onClick={handleGenerateAccountConfirmationModalOpen}
								className="commonItem"
							>
								<ProfileIcons.MyDocumentsIcon />
								<h1 className="commonText">
									{t('myDocuments_generateAccountConfirmationLetter')}
								</h1>
							</div>
							<div
								onClick={() => console.log('WIP')}
								className="commonItem"
							>
								<ProfileIcons.MyDocumentsIcon />
								<h1 className="commonText">
									{t('myDocuments_generateStatement')}
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyDocumentsLayout;
