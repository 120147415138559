import { useEffect, useState } from 'react';
import { TAsset, TMarketAsset, TWalletModalItem } from '../../../../types/types';
import { AssetIcon, FavouriteIcon, PercentChangeIcon } from '../../icons';
import { CustomCheckbox } from '../../checkboxes';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../../types/routes';
import { useAppDispatch } from '../../../../hooks';
import { setPreviousLocation } from '../../../../services/root';
import { formatNumber } from '../../../../utils';

interface WalletItemProps {
	asset?: TAsset;
	idx: number;
	isLoading?: boolean;
	baseAsset?: string;
	isBalanceHidden?: boolean;
	basePrecision?: number;
	handleSelectedAmountChange?: (i: TAsset, isSelected: boolean, idx: number) => void;
	isChecked?: boolean;
}

const WalletItem = ({
	asset,
	idx,
	isLoading,
	baseAsset,
	isBalanceHidden,
	basePrecision,
	handleSelectedAmountChange,
	isChecked,
}: WalletItemProps) => {
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const handleItemNavigateClick = () => {
		dispatch(setPreviousLocation(window.location.pathname));
		navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
	};

	return (
		<div
			key={idx}
			className="assetItem"
			onClick={
				handleSelectedAmountChange === undefined
					? handleItemNavigateClick
					: () => undefined
			}
		>
			{handleSelectedAmountChange && (
				<CustomCheckbox
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleSelectedAmountChange(asset as TAsset, e.target.checked, idx)
					}
					key={asset?.code}
					checked={isChecked}
				/>
			)}
			<AssetIcon
				isLoading={isLoading as boolean}
				className="assetIcon"
				group={asset?.group as string}
				assetName={asset?.code as string}
			/>
			<div
				className={
					handleSelectedAmountChange
						? 'innerAssetItemHeader walletConvertModalItem'
						: 'innerAssetItemHeader'
				}
			>
				{isLoading ? (
					<div className="innerAssetHeaderTextLoading"></div>
				) : (
					<h1 className="innerAssetHeaderText">{asset?.code}</h1>
				)}
				{isLoading ? (
					<div className="innerAssetBottomTextLoading"></div>
				) : (
					<h1 className="innerAssetBottomText">{asset?.name}</h1>
				)}
			</div>
			<div className="innerAssetItemAmount">
				{asset && (
					<>
						<h1 className="innerAssetItemAmountText">
							{isBalanceHidden
								? '******'
								: asset?.code === baseAsset
								? formatNumber(asset?.baseAvailable, asset?.precision)
								: formatNumber(asset?.available, asset?.precision)}
						</h1>
						{asset?.code != baseAsset && (
							<h1 className="innerAssetItemAmountUSDText">
								{isBalanceHidden
									? '******'
									: formatNumber(
											asset?.baseAvailable,
											basePrecision
									  )}{' '}
								{baseAsset}
							</h1>
						)}
					</>
				)}
			</div>
		</div>
	);
};

interface SearchAssetItemProps {
	asset?: TMarketAsset | TAsset;
	idx: number;
	globalAssetCode?: string;
	isLoading?: boolean;
	basePrecision?: number;
}

const SearchAssetItem = ({
	asset,
	idx,
	globalAssetCode,
	isLoading,
	basePrecision,
}: SearchAssetItemProps) => {
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const handleItemNavigateClick = () => {
		dispatch(setPreviousLocation(window.location.pathname));
		navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
	};

	if (isLoading) {
		return (
			<div key={idx} className="searchAssetsItem">
				<AssetIcon
					isLoading={isLoading}
					className="assetIcon"
					group=""
					assetName=""
				/>
				<div className="searchAssetTextContainer">
					<div className="searchAssetTextHeaderLoading" />
					<div className="searchAssetTextBottomLoading" />
				</div>
			</div>
		);
	}

	return (
		<div onClick={handleItemNavigateClick} key={idx} className="searchAssetsItem">
			<AssetIcon
				isLoading={false}
				className="assetIcon"
				group={asset?.group as string}
				assetName={asset?.code as string}
			/>
			<div className="searchAssetTextContainer">
				<h1 className="upperMarketItemText">{asset?.code}</h1>
				<h1 className="bottomMarketItemText">{asset?.name}</h1>
			</div>
			<div className="innerAssetItemAmount">
				{asset && (asset as TAsset)?.available && (
					<>
						<h1 className="innerAssetItemAmountText">
							{asset?.code === globalAssetCode
								? formatNumber(
										(asset as TAsset)?.baseAvailable,
										asset.precision
								  )
								: formatNumber(
										(asset as TAsset)?.available,
										asset.precision
								  )}
						</h1>
						{asset?.code != globalAssetCode && (
							<h1 className="innerAssetItemAmountUSDText">
								{formatNumber(
									(asset as TAsset)?.baseAvailable,
									basePrecision
								)}{' '}
								{globalAssetCode}
							</h1>
						)}
					</>
				)}
			</div>
		</div>
	);
};

interface MarketAssetItemProps {
	asset?: TMarketAsset;
	idx: number;
	globalAssetCode?: string;
	isLoading?: boolean;
	handleFavouriteClick?: (asset: TMarketAsset, isNeedToAdd: boolean) => void;
	isFavourite?: boolean;
	timeFrame?: string;
}

const MarketAssetItem = ({
	asset,
	idx,
	globalAssetCode,
	isLoading,
	handleFavouriteClick,
	isFavourite,
	timeFrame,
}: MarketAssetItemProps) => {
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const [isPositive, setIsPositive] = useState<boolean>(false);
	const [amount, setAmount] = useState<number>(0);

	const handleLocalFavouriteClick = () => {
		handleFavouriteClick && handleFavouriteClick(asset as TMarketAsset, !isFavourite);
	};

	useEffect(() => {
		if (timeFrame && asset) {
			if (timeFrame === `1${t('filters_h')}`) {
				setIsPositive(asset?.percentChange1h > 0);
				setAmount(asset?.percentChange1h);
			}
			if (timeFrame === `24${t('filters_h')}`) {
				setIsPositive(asset?.percentChange24h > 0);
				setAmount(asset?.percentChange24h);
			}
			if (timeFrame === `7${t('filters_d')}`) {
				setIsPositive(asset?.percentChange7d > 0);
				setAmount(asset?.percentChange7d);
			}
			if (timeFrame === `30${t('filters_d')}`) {
				setIsPositive(asset?.percentChange30d > 0);
				setAmount(asset?.percentChange30d);
			}
		}
	}, [timeFrame, asset]);

	const handleItemNavigateClick = () => {
		dispatch(setPreviousLocation(window.location.pathname));
		navigate(ROUTES.MARKET.ASSET_DETAILS.replace(':code', asset?.code as string));
	};

	return (
		<div key={idx} className="marketAssetItem">
			<FavouriteIcon
				isLoading={isLoading as boolean}
				className=""
				isActive={Boolean(isFavourite)}
				onClick={handleLocalFavouriteClick}
			/>
			<div className="marketAssetItem" onClick={handleItemNavigateClick}>
				<AssetIcon
					isLoading={isLoading as boolean}
					className="assetIcon"
					group={asset?.group?.toLowerCase() as string}
					assetName={asset?.code as string}
				/>
				{isLoading ? (
					<div className="marketAssetItemNameContainer">
						<h1 className="upperMarketItemTextLoading" />
						<h1 className="bottomMarketItemTextLoading" />
					</div>
				) : (
					<div className="marketAssetItemNameContainer">
						<h1 className="upperMarketItemText">{asset?.code}</h1>
						<h1 className="bottomMarketItemText">{asset?.name}</h1>
					</div>
				)}
				{!isLoading && asset && (
					<div className="marketAssetItemRateContainer">
						<h1 className="upperMarketItemText">
							{formatNumber(asset?.baseRateNoMarkup)} {globalAssetCode}
						</h1>
						<div className="rateChevContainer">
							<PercentChangeIcon isPositive={isPositive} className="" />
							<h1
								className={
									isPositive ? 'positivePercent' : 'negativePercent'
								}
							>
								{formatNumber(Math.abs(amount))}%
							</h1>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

interface WalletModalItemProps {
	asset: TWalletModalItem;
	idx: number;
	onClick: (i: TWalletModalItem) => void;
}

const WalletModalItem = ({ asset, idx, onClick }: WalletModalItemProps) => {
	return (
		<div onClick={() => onClick(asset)} key={idx} className="assetItem">
			<AssetIcon
				className="assetIcon"
				group={asset.code === 'USDT' ? 'Crypto' : 'Fiat'}
				assetName={asset.code}
			/>
			<div className="innerAssetItemHeader">
				<h1 className="innerAssetHeaderText">{asset?.code}</h1>
				<h1 className="innerAssetBottomText">{asset?.name}</h1>
			</div>
		</div>
	);
};

export { WalletItem, MarketAssetItem, WalletModalItem, SearchAssetItem };
