import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import i18n from './locale/i18n';
import './styles/index.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

const queryClient = new QueryClient();

const App = () => {
	useEffect(() => {
		i18n.changeLanguage(locale);
	}, []);

	return (
		<div id={'wrapper'} className={'wrapper'}>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<RouterProvider
						future={{
							v7_startTransition: true,
						}}
						router={router}
					/>
				</Provider>
			</QueryClientProvider>
		</div>
	);
};

export default App;
