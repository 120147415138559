import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import HistoryLayout from '../../components/history';

const History = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.HISTORY.INDEX}</title>
			</Helmet>
			<HistoryLayout />
		</>
	);
};

export default History;
