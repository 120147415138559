import { useMutation } from '@tanstack/react-query';
import {
	TLogoutBody,
	TSendGenerateAccountConfirmationLetterBody,
} from '../../../types/types';
import { ROUTES } from '../../../types/routes';
import { fetchRevokeToken } from '../../../api/login';
import { fetchSendGenerateAccountLetter } from '../../../api/user';

export function useRevokeTokenMutation() {
	const {
		mutateAsync: logout,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['logout'],
		mutationFn: (data: TLogoutBody) => fetchRevokeToken(data),
		onSuccess: async () => {
			const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		},
		onError: async () => {
			const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
			window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		}
	});
	return {
		logout,
		error,
		isError,
		isPending,
	};
}

export function useSendGeneralAccountLetterMutation(handleCloseModal: () => void) {
	const {
		mutateAsync: send,
		isError,
		error: error,
		isPending,
	} = useMutation({
		mutationKey: ['sendGeneralAccountConfirmationLetter'],
		mutationFn: (data: TSendGenerateAccountConfirmationLetterBody) =>
			fetchSendGenerateAccountLetter(data),
		onSuccess: async () => {
			handleCloseModal();
		},
	});
	return {
		send,
		error,
		isError,
		isPending,
	};
}
