import { customFetch } from '../../services';
import { setUserInfo, setVerificationInfo } from '../../services/user';
import { AppDispatch } from '../../store';
import { API_ROUTES } from '../../types/api.routes';
import {
	TSendGenerateAccountConfirmationLetterBody,
	TVerificationInfoBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchUserInfo(dispatch: AppDispatch, accessToken: string) {
	const userInfoData = await customFetch(API_ROUTES.USER.INFO.URL, {
		method: API_ROUTES.USER.INFO.METHOD,
		headers: { Authorization: `Bearer ${accessToken}` },
	});

	const jsonUserInfoData = await userInfoData.json();
	if (userInfoData.status != 200) {
		mapResponseError(userInfoData, jsonUserInfoData, undefined, dispatch);
	}

	dispatch(setUserInfo(jsonUserInfoData?.userData));

	return jsonUserInfoData;
}

export async function fetchVerificationInfo({
	accessToken,
	dispatch,
}: TVerificationInfoBody) {
	const verificationInfoData = await customFetch(
		API_ROUTES.USER.VERIFICATION.INFO.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.INFO.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
		}
	);

	const jsonVerificationInfoDataData = await verificationInfoData.json();
	if (verificationInfoData.status != 200) {
		mapResponseError(
			verificationInfoData,
			jsonVerificationInfoDataData,
			undefined,
			dispatch
		);
	}

	dispatch(setVerificationInfo(jsonVerificationInfoDataData));

	return jsonVerificationInfoDataData;
}

export async function fetchSendGenerateAccountLetter({
	accessToken,
	locale,
	dispatch,
}: TSendGenerateAccountConfirmationLetterBody) {
	const sendGenerateAccountLetterData = await customFetch(
		API_ROUTES.USER.GENERATE_ACCOUNT_CONFIRMATION_LETTER.URL,
		{
			method: API_ROUTES.USER.GENERATE_ACCOUNT_CONFIRMATION_LETTER.METHOD,
			headers: { Authorization: `Bearer ${accessToken}` },
			body: JSON.stringify({
				lang: locale,
			}),
		},
		true
	);

	const jsonSendGenerateAccountLetterDataData =
		await sendGenerateAccountLetterData.text();
	if (sendGenerateAccountLetterData.status != 200) {
		mapResponseError(
			sendGenerateAccountLetterData,
			jsonSendGenerateAccountLetterDataData,
			undefined,
			dispatch
		);
	}

	return jsonSendGenerateAccountLetterDataData;
}
