import { createSlice } from '@reduxjs/toolkit';
import { TAppSettings, TBiometry, TLocale, TTheme } from '../../types/types';
import { TRootState } from '../../store';

export const selectLocale = (state: TRootState) => state.root.locale;

export const selectTheme = (state: TRootState) => state.root.theme;

export const selectCounter = (state: TRootState) => state.root.counter;

export const selectIsResendCodeActive = (state: TRootState) =>
	state.root.isResendCodeActive;

export const selectIsTelegram = (state: TRootState) => state.root.isTelegram;

export const selectPreviousLocation = (state: TRootState) => state.root.previousLocation;

export const selectPreviousMainLocation = (state: TRootState) =>
	state.root.previousMainLocation;

export const selectAppSettings = (state: TRootState) => state.root.appSettings;

export const selectBiometry = (state: TRootState) => state.root.biometry;

interface IRootInitialState {
	locale: TLocale;
	theme: TTheme;
	counter: number;
	isResendCodeActive: boolean;
	isTelegram: boolean;
	appSettings: TAppSettings | undefined;
	previousLocation: string;
	previousMainLocation: string;
	biometry: TBiometry;
}

const initialState: IRootInitialState = {
	locale: window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en',
	theme:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.biometry != undefined
			? JSON.parse(
					localStorage.getItem(
						localStorage.getItem('userId') as string
					) as string
			  )?.theme
			: 'light',
	counter: 60,
	isResendCodeActive: false,
	isTelegram: false,
	appSettings: undefined,
	previousLocation: '',
	previousMainLocation: '',
	biometry:
		JSON.parse(
			localStorage.getItem(localStorage.getItem('userId') as string) as string
		)?.biometry != undefined
			? JSON.parse(
					localStorage.getItem(
						localStorage.getItem('userId') as string
					) as string
			  )?.biometry
			: {
					isBiometricAvailable: false,
					isBiometrySetup: false,
					isBiometryInited: false,
			  },
};

export const rootSlice = createSlice({
	name: 'root',
	initialState,
	reducers: {
		setLocale(state, action) {
			state.locale = action.payload;
		},
		setTheme(state, action) {
			state.theme = action.payload;

			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					theme: state.theme,
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			} else {
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify({
						theme: state.theme,
					})
				);
			}
		},
		reduceCounter(state) {
			state.counter = state.counter - 1;
		},
		updateCounter(state, action) {
			state.counter = action.payload;
		},
		setIsResendCodeActive(state, action) {
			state.isResendCodeActive = action.payload;
		},
		setIsTelegram(state, action) {
			state.isTelegram = action.payload;
		},
		setAppSettings(state, action) {
			state.appSettings = action.payload;
		},
		setPreviousLocation(state, action) {
			state.previousLocation = action.payload;
		},
		setPreviousMainLocation(state, action) {
			state.previousMainLocation = action.payload;
		},
		setBiometry(state, action) {
			Object.keys(action.payload).forEach((key: string) => {
				state.biometry[key as keyof TBiometry] = action.payload[key];
			});

			const accSettings = localStorage.getItem(
				localStorage.getItem('userId') as string
			);
			if (accSettings) {
				const parsedAccSettings = JSON.parse(accSettings);
				Object.assign(parsedAccSettings, {
					biometry: state.biometry,
				});
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify(parsedAccSettings)
				);
			} else {
				localStorage.setItem(
					localStorage.getItem('userId') as string,
					JSON.stringify({
						biometry: state.biometry,
					})
				);
			}
		},
	},
});

export const {
	setLocale,
	setTheme,
	reduceCounter,
	updateCounter,
	setIsResendCodeActive,
	setIsTelegram,
	setAppSettings,
	setPreviousLocation,
	setPreviousMainLocation,
	setBiometry,
} = rootSlice.actions;
