import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { TAsset, TMarketTab } from '../../../types/types';
import {
	BottomNavigationIcons,
	CalendarIcon,
	DepositIcon,
	ExchangeIcon,
	HistoryNavIcon,
	WithdrawIcon
} from '../icons';
import { useAppSelector } from '../../../hooks';
import { selectDepositWithdrawAssetsDictionary } from '../../../services/dictionaries';
import { useEffect, useState } from 'react';
import { CustomButton } from '../buttons';
import { useHorizontalScroll } from '../../../hooks/useHorizontalScroll';

const BottomNavigationContainer = () => {
	const location = useLocation();
	const navigate = useCustomNavigate();

	const mapIsActive = (iconName: string): boolean => {
		if (location?.pathname?.split('/')[2]?.toLowerCase() === iconName) {
			return true;
		}
		return false;
	};

	const handleNavigate = (route: string) => {
		navigate(route);
	};

	return (
		<div className='bottomNavigationContainer'>
			<div className='bottomNavigationDivider' />
			<div className='innerBottomNavigationContainer'>
				<div
					onClick={() => handleNavigate(ROUTES.MARKET.INDEX)}
					className='innerBottomNavigationItem'
				>
					<BottomNavigationIcons.BottomNavigationMarketIcon
						className={''}
						isActive={mapIsActive('market')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('market')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('assets_title')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.EXCHANGE.INDEX)}
					className='innerBottomNavigationItem'
				>
					<BottomNavigationIcons.BottomNavigationExchangeIcon
						className={''}
						isActive={mapIsActive('exchange')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('exchange')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('wallet_exchange')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.WALLET.INDEX)}
					className='innerBottomNavigationItem'
				>
					<BottomNavigationIcons.BottomNavigationWalletIcon
						className={''}
						isActive={mapIsActive('wallet')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('wallet')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('wallet_title')}
					</h1>
				</div>
			</div>
		</div>
	);
};

interface AssetDetailsNavigationContainerProps {
	code: string;
	mainRests: TAsset[] | undefined;
	spotRests: TAsset[] | undefined;
	isLoading: boolean;
}

const AssetDetailsNavigationContainer = ({
	code,
	mainRests,
	spotRests,
	isLoading
}: AssetDetailsNavigationContainerProps) => {
	const depositWithdrawAssets = useAppSelector(
		selectDepositWithdrawAssetsDictionary
	);
	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const loadingCards = ['1', '2', '3'];

	const [isAvailable, setIsAvailable] = useState<boolean>(false);

	useEffect(() => {
		if (mainRests && spotRests) {
			setIsAvailable(
				Number(
					mainRests?.find((asset) => asset.code === code)?.available
				) > 0 ||
					Number(
						spotRests?.find((asset) => asset.code === code)
							?.available
					) > 0
			);
		}
	}, [mainRests, spotRests]);

	if (isLoading) {
		return (
			<div className='marketAssetDetailsNavigationContainerLoading'>
				{loadingCards.map((value) => (
					<div
						className='marketAssetDetailsNavigationContainerItemLoading'
						key={value}
					/>
				))}
			</div>
		);
	}

	return (
		<div className='marketAssetDetailsNavigationContainer'>
			{depositWithdrawAssets?.dictionary?.deposit?.all?.includes(
				code
			) && (
				<CustomButton
					isSmall
					className='marketAssetDetailsNavigationContainerItem'
					onClick={() => console.log('WIP')}
					text={t('deposit_title') as string}
				/>
			)}
			<CustomButton
				isSmall
				className='marketAssetDetailsNavigationContainerItem'
				onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
				text={t('exchange_title') as string}
			/>
			{depositWithdrawAssets?.dictionary?.withdraw?.all?.includes(code) &&
				isAvailable && (
					<CustomButton
						isSmall
						className='marketAssetDetailsNavigationContainerItem'
						onClick={() => console.log('WIP')}
						text={t('withdraw_title') as string}
					/>
				)}
		</div>
	);
};

interface AssetsNavigationContainerProps {
	currentTab: string;
	handleTabChange: (tab: TMarketTab) => void;
	marketTabs: TMarketTab[] | undefined;
	isLoading: boolean;
	handleTimeFrameClick: () => void;
	currentTimeFrame: string;
}

const AssetsNavigationContainer = ({
	currentTab,
	handleTabChange,
	marketTabs,
	isLoading,
	handleTimeFrameClick,
	currentTimeFrame
}: AssetsNavigationContainerProps) => {
	const { elementRef, handleScrollTo } = useHorizontalScroll();

	const mapIsActiveTab = (tab: TMarketTab) => {
		if (tab.name.toLowerCase() === currentTab.toLowerCase()) {
			return true;
		}
		return false;
	};

	const handleTabClick = (event: React.MouseEvent, asset: TMarketTab) => {
		handleScrollTo(event);
		handleTabChange(asset);
	};

	return (
		<div className='assetsNavigationContainer'>
			{isLoading ? (
				<div className='scrolledAssetsNavigationLoading' />
			) : (
				<div
					ref={isLoading ? undefined : elementRef}
					className='scrolledAssetsNavigation'
				>
					{marketTabs?.map((asset, idx) => (
						<div
							key={idx}
							onClick={(event: React.MouseEvent) =>
								handleTabClick(event, asset)
							}
							className={
								'assetNavigationItem' +
								(mapIsActiveTab(asset)
									? ' assetNavigationItemActive'
									: '')
							}
						>
							<h1 className='assetNavigationItemText'>
								{asset.name}
							</h1>
						</div>
					))}
				</div>
			)}

			{isLoading ? (
				<div className='assetsDateFilterContainerLoading' />
			) : (
				<div
					className='assetsDateFilterContainer'
					onClick={handleTimeFrameClick}
				>
					<div className='assetsDateFilter'>
						<CalendarIcon />
						<h1 className='assetsDateFilterText'>
							{currentTimeFrame}
						</h1>
					</div>
				</div>
			)}
		</div>
	);
};

interface BaseProps {
	isLoading: boolean;
}

interface BaseNavigationProps extends BaseProps {
	isVerified: boolean;
	isEmpty: boolean;
}

const MainNavigationContainer = ({
	isVerified,
	isLoading,
	isEmpty
}: BaseNavigationProps) => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<>
			{isVerified && (
				<>
					<div className='mainNavigationContainer'>
						{isLoading ? (
							<>
								<div className='navigationItem'>
									<div className='navIconLoading' />
								</div>
								<div className='navigationItem'>
									<div className='navIconLoading' />
								</div>
								<div className='navigationItem'>
									<div className='navIconLoading' />
								</div>
								<div className='navigationItem'>
									<div className='navIconLoading' />
								</div>
							</>
						) : (
							<>
								{isEmpty ? (
									<div className='navigationItem'>
										<DepositIcon className='navigationIcon' />
										<h1 className='navigationText'>
											{t('wallet_deposit')}
										</h1>
									</div>
								) : (
									<>
										<div className='navigationItem'>
											<DepositIcon className='navigationIcon' />
											<h1 className='navigationText'>
												{t('wallet_deposit')}
											</h1>
										</div>
										<div
											onClick={() =>
												navigate(ROUTES.EXCHANGE.INDEX)
											}
											className='navigationItem'
										>
											<ExchangeIcon className='navigationIcon' />
											<h1 className='navigationText'>
												{t('wallet_exchange')}
											</h1>
										</div>
										<div className='navigationItem'>
											<WithdrawIcon className='navigationIcon' />
											<h1 className='navigationText'>
												{t('wallet_withdraw')}
											</h1>
										</div>
										<div className='navigationItem'>
											<HistoryNavIcon className='navigationIcon' />
											<h1 className='navigationText'>
												{t('history_title')}
											</h1>
										</div>
									</>
								)}
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};

export {
	AssetsNavigationContainer,
	BottomNavigationContainer,
	MainNavigationContainer,
	AssetDetailsNavigationContainer
};
