import { useState } from 'react';

interface CustomSwitchProps {
	onClick: () => void;
}

const CustomSwitch = ({ onClick }: CustomSwitchProps) => {
	const [isActive, setIsActive] = useState<boolean>(false);

	const handleClick = () => {
		setIsActive(!isActive);
		onClick();
	};

	return (
		<div
			className={'switchContainer' + (isActive ? ' switchContainerActive' : '')}
			onClick={handleClick}
		>
			<div
				className={
					'innerContainerEllipse' +
					(isActive ? ' innerContainerEllipseActive' : '')
				}
			/>
		</div>
	);
};

export { CustomSwitch };
