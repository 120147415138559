import { useEffect, useState } from 'react';
import { usePinInput } from 'react-pin-input-hook';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { PinInput } from '../../common/inputs';
import { CustomButton } from '../../common/buttons';
import { useLoginWithTwoFAMutation } from '../../../data/mutations/login/login';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	selectIdentityTypes,
	selectIsEnablingTwoFa,
	selectLoginData,
	selectSecretKey
} from '../../../services/auth';
import { APIError } from '../../common/error';
import { mapClassnameForMobile } from '../../../utils';
import { selectBiometry, selectIsTelegram } from '../../../services/root';
import { useTelegram } from '../../../hooks/useTelegram';

const LoginTwoFactorLayout = () => {
	const biometry = useAppSelector(selectBiometry);
	const isEnablingTwoFa = useAppSelector(selectIsEnablingTwoFa);
	const secretKey = useAppSelector(selectSecretKey);
	const isTelegram = useAppSelector(selectIsTelegram);
	const loginData = useAppSelector(selectLoginData);
	const dispatch = useAppDispatch();
	const identityTypes = useAppSelector(selectIdentityTypes);
	const [fakeError, setFakeError] = useState<boolean>(false);

	const { biometricManager } = useTelegram();

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			setFakeError(false);
		},
		onComplete: (values: string) => {
			if (values.length == 6) {
				login({
					login: loginData?.login as string,
					password: loginData?.password as string,
					twoFA: {
						code: values,
						state: loginData?.state as string,
						identityType: 'GoogleAuthenticator'
					},
					isTelegram: isTelegram,
					dispatch: dispatch,
					isEnablingTwoFa: isEnablingTwoFa,
					secretKey: secretKey,
					biometry: biometry,
					biometricManager: biometricManager
				});
			}
		},
		type: 'numeric',
		placeholder: ''
	});

	const navigate = useCustomNavigate();

	const { isError, error, login, isPending } = useLoginWithTwoFAMutation(
		navigate,
		biometry
	);

	const { t } = useTranslation();

	useEffect(() => {
		if (identityTypes.length === 0) {
			navigate(ROUTES.AUTH.LOGIN);
		}
	}, []);

	useEffect(() => {
		if (isError) {
			setFakeError(isError);
			setValues(['', '', '', '', '', '']);
		}
	}, [isError]);

	const handleBackClickButton = () => {
		navigate(ROUTES.AUTH.LOGIN);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div
					className={mapClassnameForMobile(
						'walletLayout authMainContainer'
					)}
				>
					<div className='innerContainer'>
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>
						<h1 className={'confirmEmailText'}>
							{t('twoFactorAuthCode_title')}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('twoFactorAuthCode_pleaseEnter6DigitCode')}
						</h1>
						<div className={'codeInputBlock'}>
							<PinInput
								isLoading={isPending}
								isError={fakeError}
								fields={fields}
							/>
						</div>
						<APIError isError={fakeError} error={error} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoginTwoFactorLayout;
