import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import { HeaderLayout } from '../../common/header';
import { ROUTES } from '../../../types/routes';
import { useState } from 'react';
import { selectLocale, selectTheme, setLocale, setTheme } from '../../../services/root';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import i18n from '../../../locale/i18n';
import { AboutIcons, ProfileIcons, RightChev } from '../../common/icons';
import { LocaleModal, ThemeModal, WalletModal } from '../../common/modals';
import {
	selectGlobalAsset,
	setGlobalAsset,
	setIsWalletPageInited,
	setTotalAmount,
} from '../../../services/wallet';
import { TWalletModalItem } from '../../../types/types';
import { setIsMarketPageInited } from '../../../services/market';

const AppSettingsLayout = () => {
	const locale = useAppSelector(selectLocale);
	const globalAsset = useAppSelector(selectGlobalAsset);
	const theme = useAppSelector(selectTheme);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [isLocaleModalOpen, setIsLocaleModalOpen] = useState<boolean>(false);
	const [isBaseAssetModalOpen, setIsBaseAssetModalOpen] = useState<boolean>(false);
	const [isThemeModalOpen, setIsThemeModalOpen] = useState<boolean>(false);

	const handleModalOpen = () => {
		setIsLocaleModalOpen(!isLocaleModalOpen);
	};

	const handleBaseAssetModalOpen = () => {
		setIsBaseAssetModalOpen(!isBaseAssetModalOpen);
	};

	const handleThemeModalOpen = () => {
		setIsThemeModalOpen(!isThemeModalOpen);
	};

	const handleLocaleItemClick = (i: string) => {
		setIsLocaleModalOpen(false);
		dispatch(setLocale(i));
		i18n.changeLanguage(i);
		navigate(ROUTES.PROFILE.APP_SETTINGS, i);
	};

	const handleBaseAssetClick = (i: TWalletModalItem) => {
		dispatch(setIsWalletPageInited(false));
		dispatch(setTotalAmount(0));
		dispatch(setIsMarketPageInited(false));
		setIsBaseAssetModalOpen(!isBaseAssetModalOpen);
		dispatch(setGlobalAsset(i));
	};

	const handleThemeItemClick = (i: string) => {
		dispatch(setTheme(i));
	};

	const handleBackClickButton = () => {
		navigate(ROUTES.PROFILE.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent aboutComponent'}>
				<HeaderLayout isAboutHeader isOnlyHeader={'appSettings_title'} />
				<WalletModal
					isModalOpen={isBaseAssetModalOpen}
					handleIsModalOpen={handleBaseAssetModalOpen}
					handleItemClick={handleBaseAssetClick}
					currentAsset={globalAsset}
				/>
				<LocaleModal
					isModalOpen={isLocaleModalOpen}
					handleIsModalOpen={handleModalOpen}
					handleItemClick={handleLocaleItemClick}
				/>
				<ThemeModal
					isModalOpen={isThemeModalOpen}
					handleIsModalOpen={handleThemeModalOpen}
					handleItemClick={handleThemeItemClick}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>

						<div className="commonContainer">
							<div
								onClick={handleBaseAssetModalOpen}
								className="commonItem"
							>
								<ProfileIcons.BaseAssetIcon />
								<h1 className="commonText">
									{t('settings_resultingAsset')}
								</h1>
								<h1 className="innerCommonText">{globalAsset.code}</h1>
								<RightChev />
							</div>

							<div onClick={handleThemeModalOpen} className="commonItem">
								<ProfileIcons.AppSettingsIcon />
								<h1 className="commonText">{t('settings_theme')}</h1>
								<h1 className="innerCommonText">
									{theme === 'dark'
										? t('settings_dark').replace(
												t('settings_dark').charAt(0),
												t('settings_dark').charAt(0).toUpperCase()
										  )
										: t('settings_light').replace(
												t('settings_light').charAt(0),
												t('settings_light')
													.charAt(0)
													.toUpperCase()
										  )}
								</h1>
								<RightChev />
							</div>

							<div onClick={handleModalOpen} className="commonItem">
								<AboutIcons.LanguageIcon />
								<h1 className="commonText">{t('general_language')}</h1>
								<h1 className="innerCommonText">
									{t(
										`general_${
											locale === 'en' ? 'english' : 'russian'
										}`
									)}
								</h1>
								<RightChev />
							</div>

							<div className="commonItem">
								<ProfileIcons.DateFormatIcon />
								<h1 className="commonText">{t('settings_dateFormat')}</h1>
								<h1 className="innerCommonText">
									{new Date().toLocaleDateString()}
								</h1>
							</div>

							<div
								onClick={() => navigate(ROUTES.ABOUT.LEGAL_STATEMENTS)}
								className="commonItem"
							>
								<ProfileIcons.TimeZoneIcon />
								<h1 className="commonText">{t('settings_timezone')}</h1>
								<h1 className="innerCommonText">UTC+00:00</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppSettingsLayout;
