import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { selectPreviousMainLocation } from '../../services/root';
import {
	selectFullName,
	selectIsVerified,
	selectSumSubStatus,
	selectUserVerificationInfo,
} from '../../services/user';
import { selectMainEWallet } from '../../services/wallet';
import { ROUTES } from '../../types/routes';
import { mapClassnameForMobile } from '../../utils';
import { CustomButton } from '../common/buttons';
import { HeaderLayout } from '../common/header';
import { AboutIcons, CopyIcon, ProfileIcons, RightChev } from '../common/icons';
import { useState } from 'react';
import { LogoutModal } from '../common/modals';
import { selectRegistrationDictionary } from '../../services/dictionaries';

const ProfileLayout = () => {
	const accountName = useAppSelector(selectFullName);
	const previousMainLocation = useAppSelector(selectPreviousMainLocation);
	const verificationInfo = useAppSelector(selectUserVerificationInfo);
	const sumSubStatus = useAppSelector(selectSumSubStatus);
	const registrationDictionary = useAppSelector(selectRegistrationDictionary);
	const mainEWallet = useAppSelector(selectMainEWallet);
	const isVerified = useAppSelector(selectIsVerified);

	const navigate = useCustomNavigate();
	const { t } = useTranslation();

	const [isLogoutModalOpen, setIsLogoutModalOpen] = useState<boolean>(false);

	const mapVerificationStepIdToTranslKey = () => {
		switch (verificationInfo) {
			case 'eula':
				return t('wallet_confirmYourIdentity');
			case 'profile':
				return t('wallet_confirmYourIdentity');
			case 'questionnaires':
				return t('wallet_pleaseTakeBlockchainTest');
			case 'sumsub ':
				return registrationDictionary?.dictionary?.statusSumSub?.find(
					(status) => status.statusId === sumSubStatus
				)?.statusName;
			default:
				return '';
		}
	};

	const handleLogoutModalOpen = () => {
		setIsLogoutModalOpen(!isLogoutModalOpen);
	};

	const handleBackClickButton = () => {
		navigate(previousMainLocation ? previousMainLocation : ROUTES.WALLET.INDEX);
	};

	const handleCopyClick = () => {
		navigator.clipboard.writeText(mainEWallet);
	};

	return (
		<div className={'component'}>
			<div style={{ height: 'auto' }} className={'innerComponent aboutComponent'}>
				<HeaderLayout isAboutHeader isTextHeader isOnlyHeader={accountName} />
				<LogoutModal
					isModalOpen={isLogoutModalOpen}
					handleIsModalOpen={handleLogoutModalOpen}
				/>
				<div
					style={{ marginBottom: '16px' }}
					className={mapClassnameForMobile('walletLayout')}
				>
					<div className="innerContainer">
						<CustomButton
							isBack={true}
							onClick={handleBackClickButton}
							className={'backButton'}
						/>

						<div
							style={{
								gap: '0px',
								padding: '16px 0',
								height: '92px',
							}}
							className="commonContainer"
						>
							<h1 className="accountUserIdText">
								{t('settings_ewalletId')}
							</h1>
							<div className="commonItem">
								{mainEWallet === '' ? (
									<div className="accountUserIdMainLoading" />
								) : (
									<>
										<h1 className="accountUserIdMainText">
											{mainEWallet}
										</h1>
										<CopyIcon
											onClick={handleCopyClick}
											className=""
										/>
									</>
								)}
							</div>
						</div>

						<div className="commonContainer">
							{!isVerified && (
								<div
									onClick={() => console.log('WIP')}
									className="commonItem"
								>
									<ProfileIcons.VerificationIcon />
									<div className="profileVerificationContainer">
										<h1 className="commonText">
											{t('verification_title')}
										</h1>
										<h1 className="profileVerificationInnerText">
											{mapVerificationStepIdToTranslKey()}
										</h1>
									</div>
									<RightChev />
								</div>
							)}
							<div
								onClick={() => navigate(ROUTES.PROFILE.ACCOUNT_INFO)}
								className="commonItem"
							>
								<ProfileIcons.ProfileIcon />
								<h1 className="commonText">{t('accountInfo_title')}</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.PROFILE.DOCUMENTS)}
								className="commonItem"
							>
								<ProfileIcons.MyDocumentsIcon />
								<h1 className="commonText">{t('myDocuments_title')}</h1>
								<RightChev />
							</div>
							<div
								onClick={() => console.log('WIP')}
								className="commonItem"
							>
								<ProfileIcons.ReferralIcon />
								<h1 className="commonText">
									{t('referral_free2exReferralProgram')}
								</h1>
								<RightChev />
							</div>
						</div>

						<div className="commonContainer">
							<div
								onClick={() => console.log('WIP')}
								className="commonItem"
							>
								<ProfileIcons.SecurityIcon />
								<h1 className="commonText">{t('privacy_title')}</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.PROFILE.APP_SETTINGS)}
								className="commonItem"
							>
								<ProfileIcons.AppSettingsIcon />
								<h1 className="commonText">{t('appSettings_title')}</h1>
								<RightChev />
							</div>
							<div
								onClick={() => console.log('WIP')}
								className="commonItem"
							>
								<ProfileIcons.SavedAddressesIcon />
								<h1 className="commonText">
									{t('savedAddresses_title')}
								</h1>
								<RightChev />
							</div>
							<div
								onClick={() => console.log('WIP')}
								className="commonItem"
							>
								<ProfileIcons.PriceAlertsIcon />
								<h1 className="commonText">{t('priceAlerts_title')}</h1>
								<RightChev />
							</div>
						</div>

						<div className="commonContainer">
							<div
								onClick={() => navigate(ROUTES.ABOUT.HELP)}
								className="commonItem"
							>
								<AboutIcons.HelpIcon />
								<h1 className="commonText">
									{t('settings_helpAndSupport')}
								</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.ABOUT.LEGAL_STATEMENTS)}
								className="commonItem"
							>
								<AboutIcons.LegalStatementsIcon />
								<h1 className="commonText">
									{t('settings_legalStatements')}
								</h1>
								<RightChev />
							</div>
							<div
								onClick={() => navigate(ROUTES.ABOUT.COMMUNITY)}
								className="commonItem"
							>
								<AboutIcons.CommunityIcon />
								<h1 className="commonText">{t('settings_community')}</h1>
								<RightChev />
							</div>
						</div>

						<div onClick={handleLogoutModalOpen} className="commonContainer">
							<div className="commonItem">
								<ProfileIcons.LogOutIcon />
								<h1 className="commonText">{t('settings_logOut')}</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileLayout;
